import React from 'react';

import Anchor from '../landing_page/components/anchor';
import OmadaLogo from '../landing_page/components/header_logo';
import FacebookIcon from '../landing_page/icons/social_media/facebook-icon';
import InstagramIcon from '../landing_page/icons/social_media/instagram-icon';
import LinkedinIcon from '../landing_page/icons/social_media/linkedin-icon';
import MediumCompanyIcon from '../landing_page/icons/social_media/medium-company-icon';
import TwitterIcon from '../landing_page/icons/social_media/twitter-icon';

function FourZeroFourFooter() {
  const constructOmadaLink = (path) => window.dataFromRuby.omada_b2b_url + path;
  const links = {
    Product: {
      'Success Stories': constructOmadaLink('/success-stories'),
      Outcomes: constructOmadaLink('/outcomes'),
      Security: constructOmadaLink('/security'),
      'Log In': `${window.dataFromRuby.kairos_base_url}/login`,
    },
    Company: {
      About: constructOmadaLink('/about'),
      Press: constructOmadaLink('/press'),
      Careers: constructOmadaLink('/careers'),
      Blog: constructOmadaLink('/blog'),
      Contact: 'https://support.omadahealth.com/hc/en-us/requests/new',
    },
    Support: {
      FAQ: constructOmadaLink('/frequently-asked-questions'),
      'Help Center': 'https://support.omadahealth.com/hc/en-us',
      Security: constructOmadaLink('/security'),
      Privacy: constructOmadaLink('/privacy-policy'),
    },
  };

  const constructLinkList = (linkSet) => {
    return Object.keys(linkSet).map((key) => (
      <li className='ev-footer-v2__link' key={linkSet[key]}>
        <Anchor
          anchorHref={linkSet[key]}
          anchorText={key}
          ariaLabel={key}
          section='footer'
          event={key === 'Log In' ? 'login' : 'info'}
        />
      </li>
    ));
  };

  const contructLinkSets = () => {
    return Object.keys(links).map((key) => (
      <ul key={key}>
        <li className='ev-footer-v2__link-set' role='heading' aria-level='4'>
          {key}
        </li>
        {constructLinkList(links[key])}
      </ul>
    ));
  };

  const footer_deployment_license = window.dataFromRuby.footer_deployment_license;

  return (
    <div className='ev-footer'>
      <div className='ev-footer__bottom-section u-flex u-align-items-center u-flex-column'>
        <div className='ev-footer-v2__link-section u-flex'>
          <div className='u-flex u-flex-column'>
            <div className='u-flex ev-footer-logo'>
              <OmadaLogo />
            </div>
            <div className='u-flex ev-footer-copyright'>
              {`Copyright ${new Date().getFullYear()} © Omada Health, Inc. All rights reserved.`}
            </div>
          </div>
          <div className='ev-footer-v2__navigation_links'>{contructLinkSets()}</div>
        </div>
        <div className='ev-footer-v2__additional_links u-4mb--m' role='list'>
          <Anchor
            anchorHref={constructOmadaLink('/hipaa-notice')}
            anchorText='HIPAA'
            ariaLabel='HIPAA Notice'
            section='footer'
            event='info'
            role='listitem'
          />
          <Anchor
            anchorHref={constructOmadaLink('/terms-of-use')}
            anchorText='Terms of Use'
            ariaLabel='Terms of Use'
            section='footer'
            event='info'
            role='listitem'
          />
          <Anchor
            anchorHref={constructOmadaLink('/member-rights-and-responsibilities')}
            anchorText='Member Rights and Responsibilities'
            ariaLabel='Member Rights and Responsibilities'
            section='footer'
            event='info'
            role='listitem'
          />
        </div>
        <hr className='u-4mb--m t-bc--grey-3 footer-v2__copyright-divider site-footer__divider' />
        <div className='ev-footer-v2__copyright-section u-4mb--m'>
          <div className='u-flex'>
            {footer_deployment_license && (
              <p
                className='ev-footer-disclaimer qa-footer-deployment-license u-flex'
                dangerouslySetInnerHTML={{ __html: footer_deployment_license }}
              />
            )}
          </div>
          <div className='ev-footer-v2__copyright-social-icon-wrapper u-4mt--xxs u-flex'>
            <Anchor
              anchorHref='https://www.facebook.com/omadahealth'
              ariaLabel='Facebook'
              classNames='u-4mr--xxs footer-v2__copyright-social-icon'
              section='footer'
              event='info'
              type='icon'
            >
              <FacebookIcon />
            </Anchor>

            <Anchor
              anchorHref='https://twitter.com/omadahealth'
              ariaLabel='Twitter'
              classNames='u-4mr--xxs footer-v2__copyright-social-icon'
              section='footer'
              event='info'
              type='icon'
            >
              <TwitterIcon />
            </Anchor>

            <Anchor
              anchorHref='https://www.linkedin.com/company/omada-health'
              ariaLabel='LinkedIn'
              classNames='u-4mr--xxs footer-v2__copyright-social-icon'
              section='footer'
              event='info'
              type='icon'
            >
              <LinkedinIcon />
            </Anchor>

            <Anchor
              anchorHref='https://instagram.com/omadahealth'
              ariaLabel='Instagram'
              classNames='u-4mr--xxs footer-v2__copyright-social-icon'
              section='footer'
              event='info'
              type='icon'
            >
              <InstagramIcon />
            </Anchor>

            <Anchor
              anchorHref='https://medium.com/omada-health'
              ariaLabel='Medium'
              classNames='u-4mr--xxs footer-v2__copyright-social-icon'
              section='footer'
              event='info'
              type='icon'
            >
              <MediumCompanyIcon />
            </Anchor>
          </div>
        </div>
        <hr className='u-4mb--m t-bc--grey-3 footer-v2__copyright-divider site-footer__divider' />
      </div>
    </div>
  );
}

export default FourZeroFourFooter;
